import React, { Component } from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "../../assets/css/slider.css";

const slides = [
  {
    title: "Taj Al Moulouk",
    btnLearn: "-- Learn More --",
    finan: "Taj Al Moulouk",
    dox: "One of the oldest Arabic sweet shops in Beirut",
    btnService: "-- Our Services --",
    image: require("./../../assets/images/main-slider/2.jpg"),
  },
  {
    title: "Taj Al Moulouk",
    btnLearn: "-- Learn More --",
    finan: "Taj Al Moulouk",
    dox: "One of the oldest Arabic sweet shops in Beirut",
    btnService: "-- Our Services --",
    image: require("./../../assets/images/main-slider/1.jpg"),
  },
];

class HomeSlider2 extends Component {
  render() {
    return (
      <>
        <Slider className="slider-wrapper" autoplay>
          {slides.map((slide, index) => (
            <div  id="main"
              key={index}
              className="slider-content"
              style={{
                background: `url('${slide.image}') no-repeat center center`,
              }}
            >
              <div className="inner text-center inner-2">
                <h3 className="text-white fw-3 fs-2">{slide.title}</h3>
                <h4 className="text-white fw-3 fs-2">
                  {slide.dox}
                </h4>
              </div>
            </div>
          ))}
        </Slider>
      </>
    );
  }
}
export default HomeSlider2;
