import React, { Component } from "react";
import Loader from "../element/loader"
import Header2 from "../layout/header2";
import HomeSlider2 from "../element/home-slider2";
import Footer from "../layout/footer";
import PortfolioFilter1 from "./../element/portfolio-filter1";
import "../../assets/css/color-2.css";

class Index2 extends Component {
  render() {
    return (
      <>
        <Loader />
        <Header2 />
        <HomeSlider2 />
        <section className="about-section-two about-section" id="about">
          <div className="auto-container" id="about">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="content-box wow fadeInUp m-0"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="sec-title">
                    <h2>About Us</h2>
                    <div className="text fw-bold text-justify">
                      Feel the pleasure of taste from the sweets and ice-cream
                      of
                      <span className="accent-color accent-font main-font">
                        {" "}
                        Taj Al Moulouk{" "}
                      </span>
                      as a result of collaboration between the best natural
                      components with our long experience of making Middle
                      Eastern sweets. We have developed all kinds of Middle
                      Eastern sweets in order to give you better taste, better
                      quality and a variety of taste while reserving the genuine
                      tradition. We have tried and always trying our best to
                      keep our products the best within local and international
                      markets. Our goal is always to satisfy our clients and
                      gain their precious trust. In our branch in Lebanon, we
                      represent the first machinery factory to produce sweets
                      and Italian ice-cream that is never touched by hands. Our
                      ice-cream has the specialty of forty natural flavor in
                      addition to the splendid Gateau Classé and chocolate.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 justify-content-center d-flex">
                <div className="image-one mb-4 mb-lg-0">
                  <img
                    src={require("../../assets/images/logo.png")}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="image-wrapper wow fadeInUp mb-4 mb-lg-0"
                  data-wow-delay="200ms"
                  data-wow-duration="1200ms"
                >
                  <div className="image-one mb-0">
                    <img
                      src={require("../../assets/images/about.jpg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-4 mb-lg-0 align-self-center">
                <div className="text fw-bold text-justify">
                  Our branch in Lebanon is located at the capital Beirut at
                  Bliss street which is one of the most important and ancient
                  streets. We have always been and still classified by the
                  ministry of tourism as FOUR STARS - Class A (Excellent Degree)
                </div>
                <div className="text fw-bold text-justify">
                  <span className="accent-color accent-font main-font">
                    Taj Al Moulouk
                  </span>{" "}
                  has earned many classifications and certificates as follows:
                </div>
                <ul>
                  <li>Health Certificate</li>
                  <li>
                    Classified FOUR STARS - Class A by the ministry of tourism
                  </li>
                  <li>Certified as one of the Classified Pastries</li>
                  <li>Certified by the ministry of economy and industry</li>
                  <li>Certified by the chamber of industry and economy</li>
                  <li>Certified from the ministry of tourism</li>
                  <li>
                    Certified from the Boeker Company for good quality and
                    supervision
                  </li>
                  <li>We offer to export our sweets outside of Lebanon</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <PortfolioFilter1 />
        <Footer />
      </>
    );
  }
}
export default Index2;
