import React, { Component } from "react";

import { NavHashLink } from "react-router-hash-link";

class Menu extends Component {
  render() {
    return (
      <>
        <div
          className="collapse navbar-collapse show clearfix"
          id="navbarSupportedContent"
        >
          <ul className="navigation clearfix">
            <li>
              <NavHashLink
                smooth
                to="#main"
                activeClassName="accent-color"
                activeStyle={{ color: "var(--accent-color2)" }}
              >
                Home
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                smooth
                to="#about"
                activeClassName="accent-color"
                activeStyle={{ color: "var(--accent-color2)" }}
              >
                About
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                smooth
                to="#gallery"
                activeClassName="accent-color"
                activeStyle={{ color: "var(--accent-color2)" }}
              >
                Gallery
              </NavHashLink>
            </li>
            <li className="px-4 px-lg-0 py-4">
              <NavHashLink
                smooth
                to="#contact"
                className="theme-btn btn-style-one contact-button p-0"
              >
                <span className="btn-title">Contact Us</span>
              </NavHashLink>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
export default Menu;
