import React, { Component } from "react";
import "react-animated-slider/build/horizontal.css";
import "../../assets/css/slider.css";

import $ from 'quantdom';

window.onload = function(){
    $(".loader-area").addClass('animate__fadeOut');
    $(".loader-area").addClass('z-1');
};


class Loader extends Component {

  render() {
    return (
      <>
        <div className="loader-area animate__animated ">
          <div className="loader">
            <div className="shapes-10"></div>
          </div>
        </div>
      </>
    );
  }
}
export default Loader;
