import React, { useRef } from "react";
import { Link } from "react-router-dom";

import emailjs from "@emailjs/browser";

import { NavHashLink } from "react-router-hash-link";

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zaz54h4",
        "template_il11zu1",
        form.current,
        "Dl7dp3kktPYhk5Df5"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Message Sent..");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <footer className="main-footer" id="contact">
        <div className="auto-container">
          <div className="widgets-section">
            <div className="row clearfix">
              <div className="column col-lg-4">
                <div className="footer-widget logo-widget">
                  <div className="widget-content">
                    <div className="footer-logo">
                      <Link to="index.html">
                        <img
                          className=""
                          src={require("../../assets/images/logo.png")}
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="text">
                      Feel the pleasure of taste from the sweets and ice-cream
                      of Taj Al Moulouk as a result of collaboration between the
                      best natural components with our long experience of making
                      Middle Eastern sweets.{" "}
                      <NavHashLink className="fw-bold" smooth to="#about">
                        Learn More
                      </NavHashLink>
                    </div>
                    <ul className="social-links clearfix">
                      <li>
                        <Link to={"/#"}>
                          <span className="fab fa-facebook-f"></span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <span className="fab fa-twitter"></span>
                        </Link>
                      </li>
                      <li>
                        <Link to={"/#"}>
                          <span className="fab fa-instagram"></span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="column col-lg-4">
                <div className="footer-widget links-widget">
                  <div className="widget-content">
                    <h3>Contact Us</h3>
                    <div className="row">
                      <div className="col-md-12">
                        <ul>
                          <li>
                            Bliss Street - Hamra <br />
                            Beirut - Lebanon
                          </li>
                          <li>
                            <a href="tel:009611365797">+961-1-365797</a>
                          </li>
                          <li>
                            <a href="tel:009613365797">+961-3-365797</a>
                          </li>
                          <li>
                            <a href="mailto:info@tajalmoulouk-lb.com">
                              info@tajalmoulouk-lb.com
                            </a>
                          </li>
                          <li>
                            <a href="mailto:tajalmoulouk@tajalmoulouk-lb.com">
                              tajalmoulouk@tajalmoulouk-lb.com
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column col-lg-4">
                <div className="footer-widget instagram-widget">
                  <h3>Send Us a Message</h3>
                  <form className="form row" ref={form} onSubmit={sendEmail}>
                    <div className="form-group col-12">
                      <label htmlFor="userName">Name</label>
                      <input
                        id="userName"
                        className="form-control"
                        type="text"
                        name="user_name"
                      required/>
                    </div>
                    <div className="form-group col-12">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        className="form-control"
                        type="email"
                        name="user_email"
                      required/>
                    </div>
                    <div className="form-group col-12">
                      <label htmlFor="msg">Message</label>
                      <textarea
                        id="msg"
                        className="form-control"
                        name="message"
                      required/>
                    </div>
                    <div className="col-12">
                      <div
                        className="g-recaptcha"
                        data-sitekey="6LfoT-wfAAAAADBGvMDLsMEf9X-j0oV_u_C6uqGO"
                      ></div>
                      <br />
                      <div className="theme-btn btn-style-one footer contact-button p-0">
                        <input
                          className="btn-title"
                          type="submit"
                          value="Submit"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="auto-container">
          <div className="footer-bottom">
            <div className="copyright">
              Copyright &#169; 2022 - <a href="https://tajalmoulouk-lb.com/">Taj Al Moulouk</a>
              <br />
              Developed by <a href="https://webspark.group" target="_blank" rel="noopener noreferrer">webspark</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
