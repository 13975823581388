import React, { useState } from "react";
import { Link } from "react-router-dom";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'animate.css';

import $ from "quantdom";

document.addEventListener("DOMContentLoaded", function (event) {
  $(".filter-tabs li").on("click", function () {
    $(".filter-tabs li").removeClass("active")
    $(this).addClass("active");
  });
});

const images = [
  {
    id: 1,
    name: "Ramadan Sweets",
    title: "Dates - Qlowbat",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/dates-qlowbat.jpg"),
  },
  {
    id: 2,
    name: "Ramadan Sweets",
    title: "Othmaleye Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/othmaleye-ashta.jpg"),
  },
  {
    id: 3,
    name: "Ramadan Sweets",
    title: "Kalaj Ramadan - Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/kalaj-ramadan-ashta.jpg"),
  },
  {
    id: 4,
    name: "Ramadan Sweets",
    title: "Ward Al Sham",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/ward-al-sham.jpg"),
  },
  {
    id: 5,
    name: "Ramadan Sweets",
    title: "Shaaybeyat",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/shaaybeyat-Ashta-fresh.jpg"),
  },
  {
    id: 6,
    name: "Ramadan Sweets",
    title: "Znood Al Set",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/znood-al-set.jpg"),
  },
  {
    id: 7,
    name: "Ramadan Sweets",
    title: "Namoura Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/namoura-ashta.jpg"),
  },
  {
    id: 8,
    name: "Ramadan Sweets",
    title: "Mad Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/mad-ashta.jpg"),
  },
  {
    id: 9,
    name: "Ramadan Sweets",
    title: "Znood Al Set - Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/znood-al-set-ashta.jpg"),
  },
  {
    id: 10,
    name: "Ramadan Sweets",
    title: "Ward al Sham - Shaaybeyat - Ghraybe",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/ward-al-sham-shaaybeyat-ghraybe.jpg"),
  },
  {
    id: 11,
    name: "Ramadan Sweets",
    title: "Konafa",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/konafa.jpg"),
  },
  {
    id: 12,
    name: "Ramadan Sweets",
    title: "Warbat Ashta - Nuts",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/warbat-ashta-nuts.jpg"),
  },
  {
    id: 13,
    name: "Ramadan Sweets",
    title: "Ghraybe Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/ghraybe-ashta.jpg"),
  },
  {
    id: 14,
    name: "Ramadan Sweets",
    title: "Konafa & Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/konafa-and-ashta.jpg"),
  },
  {
    id: 15,
    name: "Ramadan Sweets",
    title: "Maakaroon",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/maakaroon.jpg"),
  },
  {
    id: 16,
    name: "Ramadan Sweets",
    title: "Halawat Al Joben",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/halawat-al-joben.jpg"),
  },
  {
    id: 17,
    name: "Ramadan Sweets",
    title: "Qouzi",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/qouzi.jpg"),
  },
  {
    id: 18,
    name: "Ramadan Sweets",
    title: "Hadaf Ramadan Nuts",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/hadaf-ramadan-nuts.jpg"),
  },
  {
    id: 19,
    name: "Ramadan Sweets",
    title: "Hadaf Ramadan Nuts - Box",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/hadaf-ramadan-nuts-box.jpg"),
  },
  {
    id: 20,
    name: "Ramadan Sweets",
    title: "Baklawa Nuts",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/baklawa-nuts.jpg"),
  },
  {
    id: 21,
    name: "Ramadan Sweets",
    title: "Madloka Ashta & Pistachio",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/Madloka-ashta-and-Pistachio.jpg"),
  },
  {
    id: 22,
    name: "Ramadan Sweets",
    title: "Mafroka Qolobat & Ashta",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/mafroka-qolobat-and-ashta.jpg"),
  },
  {
    id: 23,
    name: "Ramadan Sweets",
    title: "Mafroka Pistachio",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/mafroka-pistachio.jpg"),
  },
  {
    id: 24,
    name: "Ramadan Sweets",
    title: "Karabij",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/karabij.jpg"),
  },
  {
    id: 25,
    name: "Ramadan Sweets",
    title: "Karabij - Box",
    ramadanSweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/ramadan/karabij-box.jpg"),
  },
  {
    id: 26,
    name: "Everyday Sweets",
    title: "Esh Al Bolbol Cashew",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/esh-al-bolbol-cashew.jpg"),
  },
  {
    id: 27,
    name: "Everyday Sweets",
    title: "Esh Al Bolbol Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/esh-al-bolbol-pistachio.jpg"),
  },
  {
    id: 28,
    name: "Everyday Sweets",
    title: "Baklava Box",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/baklava-box.jpg"),
  },
  {
    id: 29,
    name: "Everyday Sweets",
    title: "Baklava",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/baklava.jpg"),
  },
  {
    id: 30,
    name: "Everyday Sweets",
    title: "Baklava Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/baklava-pistachio.jpg"),
  },
  {
    id: 31,
    name: "Everyday Sweets",
    title: "Baklava Pine",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/baklava-pine.jpg"),
  },
  {
    id: 32,
    name: "Everyday Sweets",
    title: "Barma Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/barma-pistachio.jpg"),
  },
  {
    id: 33,
    name: "Everyday Sweets",
    title: "Baloreye Pistachio & Maward",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/baloreye-pistachio-and-maward.jpg"),
  },
  {
    id: 34,
    name: "Everyday Sweets",
    title: "Hadaf",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/hadaf.jpg"),
  },
  {
    id: 35,
    name: "Everyday Sweets",
    title: "Baklava Nuts",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/baklava-nuts.jpg"),
  },
  {
    id: 36,
    name: "Everyday Sweets",
    title: "Hadaf Nuts",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/hadaf-nuts.jpg"),
  },
  {
    id: 37,
    name: "Everyday Sweets",
    title: "Kol w Shkor Pine",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/kol-w-shkor-pine.jpg"),
  },
  {
    id: 38,
    name: "Everyday Sweets",
    title: "Basma Pine",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/basma-pine.jpg"),
  },
  {
    id: 39,
    name: "Everyday Sweets",
    title: "Madd Dates",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/madd-dates.jpg"),
  },
  {
    id: 40,
    name: "Everyday Sweets",
    title: "Madd Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/madd-pistachio.jpg"),
  },
  {
    id: 41,
    name: "Everyday Sweets",
    title: "Madd Nuts",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/madd-nuts.jpg"),
  },
  {
    id: 42,
    name: "Everyday Sweets",
    title: "Madd Ashta",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/madd-ashta.jpg"),
  },
  {
    id: 43,
    name: "Everyday Sweets",
    title: "Madd Varied - Extra",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/madd-varied-extra.jpg"),
  },
  {
    id: 44,
    name: "Everyday Sweets",
    title: "Namoura Qolobat",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/namoura-qolobat.jpg"),
  },
  {
    id: 45,
    name: "Everyday Sweets",
    title: "Znod Al Set Ashta",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/znod-al-set-ashta.jpg"),
  },
  {
    id: 46,
    name: "Everyday Sweets",
    title: "Madd Pistachio, Nuts & Dates - Extra",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/madd-pistachio-nuts-and-dates-extra.jpg"),
  },
  {
    id: 47,
    name: "Everyday Sweets",
    title: "Namoura Almond & Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/namoura-almond-and-pistachio.jpg"),
  },
  {
    id: 48,
    name: "Everyday Sweets",
    title: "Znod Al Set",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/znod-al-set.jpg"),
  },
  {
    id: 49,
    name: "Everyday Sweets",
    title: "Namoura Almond & Pistachio + Sfof",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/namoura-almond-and-pistachio-sfof.jpg"),
  },
  {
    id: 50,
    name: "Everyday Sweets",
    title: "Halawat Al Jeben",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/halawat-al-jeben.jpg"),
  },
  {
    id: 51,
    name: "Everyday Sweets",
    title: "Ward Al Sham",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/ward-al-sham.jpg"),
  },
  {
    id: 52,
    name: "Everyday Sweets",
    title: "Shaaybeyat Ashta",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/shaaybeyat-ashta.jpg"),
  },
  {
    id: 53,
    name: "Everyday Sweets",
    title: "Namoura Ashta",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/namoura-ashta.jpg"),
  },
  {
    id: 54,
    name: "Everyday Sweets",
    title: "Maamoul Nuts (Middle Size)",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-nuts-middle-size.jpg"),
  },
  {
    id: 55,
    name: "Everyday Sweets",
    title: "Maamoul",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul.jpg"),
  },
  {
    id: 56,
    name: "Everyday Sweets",
    title: "Maamoul Pistachio (Middle Size) - Box",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-pistachio-middle-size-box.jpg"),
  },
  {
    id: 57,
    name: "Everyday Sweets",
    title: "Maamoul Nuts Special Coated (Big Size)",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-nuts-special-coated-big-size.jpg"),
  },
  {
    id: 58,
    name: "Everyday Sweets",
    title: "Maamoul Pistachio Special Coated (Big Size)",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-pistachio-special-coated.jpg"),
  },
  {
    id: 59,
    name: "Everyday Sweets",
    title: "Sesame & Dates with Hazelnuts",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/sesame-and-dates-with-hazelnuts.jpg"),
  },
  {
    id: 60,
    name: "Everyday Sweets",
    title: "Maamoul Dates (Middle Size) - Box",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-dates-middle-size-box.jpg"),
  },
  {
    id: 61,
    name: "Everyday Sweets",
    title: "Heaven's Fruit",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/heaven's-fruit.jpg"),
  },
  {
    id: 62,
    name: "Everyday Sweets",
    title: "Dates with Hazelnuts",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/dates-with-hazelnuts.jpg"),
  },
  {
    id: 63,
    name: "Everyday Sweets",
    title: "Maamoul Dates Special Coated (Big Size)",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-dates-special-coated-big-size.jpg"),
  },
  {
    id: 64,
    name: "Everyday Sweets",
    title: "Maamoul Dates & Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-dates-and-pistachio.jpg"),
  },
  {
    id: 65,
    name: "Everyday Sweets",
    title: "Maamoul Dates",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-dates.jpg"),
  },
  {
    id: 66,
    name: "Everyday Sweets",
    title: "Dates Qolobat",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/dates-qolobat.jpg"),
  },
  {
    id: 67,
    name: "Everyday Sweets",
    title: "Maamoul Dates & Qolobat",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maamoul-dates-and-qolobat.jpg"),
  },
  {
    id: 68,
    name: "Everyday Sweets",
    title: "Ghraybe",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/ghraybe.jpg"),
  },
  {
    id: 69,
    name: "Everyday Sweets",
    title: "Barazeq - Ghraybe - Maamoul Dates",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/barazeq-ghraybe-maamoul-dates.jpg"),
  },
  {
    id: 70,
    name: "Everyday Sweets",
    title: "Barazeq",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/barazeq.jpg"),
  },
  {
    id: 71,
    name: "Everyday Sweets",
    title: "Marsaban Pistachio & Almond",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/marsaban-pistachio-and-almond.jpg"),
  },
  {
    id: 72,
    name: "Everyday Sweets",
    title: "Karabij",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/karabij.jpg"),
  },
  {
    id: 73,
    name: "Everyday Sweets",
    title: "Karabij Box",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/karabij-box.jpg"),
  },
  {
    id: 74,
    name: "Everyday Sweets",
    title: "Ward Al sham - Shaaybeyat - Ghraybe",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/ward-al-sham-shaaybeyat-ghraybe.jpg"),
  },
  {
    id: 75,
    name: "Everyday Sweets",
    title: "Konafa",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/konafa.jpg"),
  },
  {
    id: 76,
    name: "Everyday Sweets",
    title: "Konafa & Ashta",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/konafa-and-ashta.jpg"),
  },
  {
    id: 77,
    name: "Everyday Sweets",
    title: "Jazareye Almond & Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/jazareye-almond-and-pistachio.jpg"),
  },
  {
    id: 78,
    name: "Everyday Sweets",
    title: "Maakaroon",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/maakaroon.jpg"),
  },
  {
    id: 79,
    name: "Everyday Sweets",
    title: "Qolobat",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/qolobat.jpg"),
  },
  {
    id: 80,
    name: "Everyday Sweets",
    title: "Mafroka Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/mafroka-pistachio.jpg"),
  },
  {
    id: 81,
    name: "Everyday Sweets",
    title: "Mafroka Qolobat & Ashta",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/mafroka-qolobat-and-ashta.jpg"),
  },
  {
    id: 82,
    name: "Everyday Sweets",
    title: "Madloka Ashta & Pistachio",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/madloka-ashta-and-pistachio.jpg"),
  },
  {
    id: 83,
    name: "Everyday Sweets",
    title: "Light Chocolate",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/light-chocolate.jpg"),
  },
  {
    id: 84,
    name: "Everyday Sweets",
    title: "Milk & Dark Chocolate",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/milk-and-dark-chocolate.jpg"),
  },
  {
    id: 85,
    name: "Everyday Sweets",
    title: "Malban & Aramish Extra",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/malbanand-aramish-extra.jpg"),
  },
  {
    id: 86,
    name: "Everyday Sweets",
    title: "Mazaher & Mawared",
    everydaySweets: true,
    imgUrl: require("../../assets/images/gallery/sweets/everyday/mazaher-and-mawared.jpg"),
  },
  {
    id: 87,
    name: "Special Sweets",
    title: "Quozi",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/quozi.jpg"),
  },
  {
    id: 88,
    name: "Special Sweets",
    title: "Taj Al Moulouk Special",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/taj-al-moulouk-special.jpg"),
  },
  {
    id: 89,
    name: "Special Sweets",
    title: "Fakehat Al Janna",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/fakehat-al-janna.jpg"),
  },
  {
    id: 90,
    name: "Special Sweets",
    title: "Barazeq",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/barazeq.jpg"),
  },
  {
    id: 91,
    name: "Special Sweets",
    title: "Dates with Nuts",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/dates-with-nuts.jpg"),
  },
  {
    id: 92,
    name: "Special Sweets",
    title: "Shaaybeyat Ashta Fresh",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/shaaybeyat-ashta-fresh.jpg"),
  },
  {
    id: 93,
    name: "Special Sweets",
    title: "Mafroke Pistachio",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/mafroke-pistachio.jpg"),
  },
  {
    id: 94,
    name: "Special Sweets",
    title: "Ward Al Sham",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/ward-al-sham.jpg"),
  },
  {
    id: 95,
    name: "Special Sweets",
    title: "Baklawa Pistachio",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/baklawa-pistachio.jpg"),
  },
  {
    id: 96,
    name: "Special Sweets",
    title: "Maamoul Special",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/maamoul-special.jpg"),
  },
  {
    id: 97,
    name: "Special Sweets",
    title: "Karabij",
    special: true,
    imgUrl: require("../../assets/images/gallery/sweets/specials/karabij.jpg"),
  },

  {
    id: 98,
    name: "Ice Cream",
    title: "Banana Boat",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/banana-boat.jpg"),
  },
  {
    id: 99,
    name: "Ice Cream",
    title: "Ashta Shameye",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/ashta-shameye.jpg"),
  },
  {
    id: 100,
    name: "Ice Cream",
    title: "Valentino",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/valentino.jpg"),
  },
  {
    id: 101,
    name: "Ice Cream",
    title: "Kiwi Cream",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/kiwi-cream.jpg"),
  },
  {
    id: 102,
    name: "Ice Cream",
    title: "Banana Paradise",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/banana-paradise.jpg"),
  },
  {
    id: 103,
    name: "Ice Cream",
    title: "Coupes Des Iles",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/coupes-des-iles.jpg"),
  },
  {
    id: 104,
    name: "Ice Cream",
    title: "Cocktail Juice",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/cocktail-juice.jpg"),
  },
  {
    id: 105,
    name: "Ice Cream",
    title: "Exotic",
    iceCream: true,
    imgUrl: require("../../assets/images/gallery/ice-cream/exotic.jpg"),
  },
];

const FILTER_DEFS = {
  ramadanSweets: (image) => image.ramadanSweets,
  everydaySweets: (image) => image.everydaySweets,
  special: (image) => image.special,
  iceCream: (image) => image.iceCream,
  NONE: (image) => image,
};

const PortfolioFilter1 = (props) => {
  const [state, setState] = useState({
    list: images,
    filterKey: "NONE",
  });

  const { list, filterKey } = state;
  const filteredList = list.filter(FILTER_DEFS[filterKey]);
  return (
    <>
      <section className="gallery-section" id="gallery">
        <div className="sortable-masonry">
          <div className="auto-container">
            <div className="row m-0 justify-content-md-between align-items-center">
              <div className="sec-title">
                <div className="sub-title">Sweets</div>
                <h2>Gallery</h2>
              </div>
              {/* <!--Filter--> */}
              <div
                className="filters text-center wow fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1200ms"
              >
                <ul className="filter-tabs filter-btns">
                  <li
                    className="active filter"
                    onClick={() => setState({ ...state, filterKey: "NONE" })}
                  >
                    <span>All</span>
                  </li>
                  <li
                    className="filter"
                    onClick={() =>
                      setState({ ...state, filterKey: "ramadanSweets" })
                    }
                  >
                    <span>Ramadan Sweets</span>
                  </li>
                  <li
                    className="filter"
                    onClick={() =>
                      setState({ ...state, filterKey: "everydaySweets" })
                    }
                  >
                    <span>Everyday Sweets</span>
                  </li>
                  <li
                    className="filter"
                    onClick={() => setState({ ...state, filterKey: "special" })}
                  >
                    <span>Taj Al Moulouk Special</span>
                  </li>
                  <li
                    className="filter"
                    onClick={() =>
                      setState({ ...state, filterKey: "iceCream" })
                    }
                  >
                    <span>Ice Cream</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="auto-container">
            <div className="items-container row">
              {filteredList.map((image) => (
                
                <div
                  className="col-lg-3 col-md-6 gallery-block-one"
                  key={image.id}
                >
                  <div className="inner-box">
                    <div className="image">
                      <LazyLoadImage src={image.imgUrl} alt={image.title} effect="opacity"/>
                    </div>
                    <div className="caption-title">
                      <h5>{image.name}</h5>
                      <h3>
                        <Link to={"/#"}>{image.title}</Link>
                      </h3>
                      <div className="view-project">
                        {/* <Link
                          data-fancybox
                          to={image.imgUrl}
                          className="zoom-btn"
                        >
                          <span>+</span>
                        </Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioFilter1;
