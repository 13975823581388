import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Index from "./pages/index";
// import RtlHome from "./pages/rtl-home";
import BackToTop from "./layout/backToTop";



class Router extends Component {
  render() {
    return (
      <BrowserRouter basename={""}>
        {/* <BrowserRouter> */}
        <div className="page-wrapper">
          <Switch>
            <Route path="/" exact component={Index} />
            {/* <Route path='/rtl-home' component={RtlHome} /> */}
          </Switch>

          <BackToTop />
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
