import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu";

import { NavHashLink } from "react-router-hash-link";

class Header2 extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    var btn = document.querySelector(".mobile-nav-toggler");
    var closeBtn = document.querySelector(".close-btn");
    var body = document.getElementsByTagName("body")[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }
    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    btn.addEventListener("click", addFunc);
    closeBtn.addEventListener("click", closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = (event) => {
    if (window.scrollY > 100) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  render() {
    const { scrolled } = this.state;
    return (
      <>
        <header
          className={
            scrolled
              ? "main-header header-style-two fixed-header"
              : "main-header header-style-two"
          }
        >
          <div className="header-top-two">
            <div className="auto-container">
              <div className="inner">
                <div className="top-left">
                  <ul className="contact-info">
                    <li>
                      <a href="mailto:info@tajalmoulouk-lb.com">
                        <i className="far fa-envelope"></i>
                        info@tajalmoulouk-lb.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:009611365797">
                        <i className="far fa-phone"></i>+961-1-365797
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="top-right">
                  <div className="language d-none">
                    <form action="#" className="language-switcher">
                      <select className="selectpicker d-block">
                        <option value="en">EN</option>
                        <option value="ar">AR</option>
                      </select>
                    </form>
                  </div>
                  <ul className="clearfix social-links">
                    <li>
                      <Link to={"/#"}>
                        <span className="fab fa-twitter"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/#"}>
                        <span className="fab fa-facebook-square"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/#"}>
                        <span className="fab fa-instagram"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to={"/#"}>
                        <span className="fab fa-youtube"></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper">
            <div className="container-fluid p-0">
              <div className="inner-container mt-0">
                <div className="nav-outer clearfix">
                  <div className="logo-box">
                    <div className="logo">
                      <NavHashLink smooth to="#main">
                        <img
                          src={require("../../assets/images/logo-1.png")}
                          alt=""
                        />
                      </NavHashLink>
                    </div>
                  </div>

                  <div className="mobile-nav-toggler">
                    <span className="icon fal fa-bars"></span>
                  </div>

                  <nav className="main-menu navbar-expand-md navbar-light float-right mr-3">
                    <Menu />
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              scrolled ? "sticky-header animated slideInDown" : "sticky-header"
            }
          >
            <div className="auto-container clearfix">
              <div className="logo pull-left">
                <Link to={"/#"} title="">
                  <img
                    src={require("../../assets/images/logo.png")}
                    alt=""
                    title=""
                  />
                </Link>
              </div>

              <div className="pull-right">
                <nav className="main-menu clearfix">
                  <Menu />
                </nav>
              </div>
            </div>
          </div>

          <div className="mobile-menu">
            <div className="menu-backdrop"></div>
            <div className="close-btn">
              <span className="icon flaticon-cancel"></span>
            </div>

            <nav className="menu-box">
              <div className="nav-logo">
                <Link to={"/#"}>
                  <img src="assets/images/logo.png" alt="" title="" />
                </Link>
              </div>
              <div className="menu-outer">
                <Menu />
              </div>

              <div className="social-links">
                <ul className="clearfix">
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-twitter"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-facebook-square"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-instagram"></span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/#"}>
                      <span className="fab fa-youtube"></span>
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      </>
    );
  }
}
export default Header2;
